import React from 'react';
import { Input, Button, Textarea, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, checkPermission } from 'utils';
import { connect } from 'react-redux';
import { UserService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalBank extends React.Component<Props> {

	state = {
		banks: [],
		phonecodes: [],
		document_types: [],
		form: {
			payment_mobile_document_type_id: '',
			payment_mobile_document: '',
			payment_mobile_phonecode_id: '',
			payment_mobile_phone: '',
			payment_mobile_bank_id: ''
		}
	}

	componentDidMount() {
		const person = this.props.item?.person;
		if (person) {
			this.setState({
				form: {
					...this.state.form,
					payment_mobile_document_type_id: person?.payment_mobile_document_type_id || '',
					payment_mobile_document: person?.payment_mobile_document || '',
					payment_mobile_phonecode_id: person?.payment_mobile_phonecode_id || '',
					payment_mobile_phone: person?.payment_mobile_phone || '',
					payment_mobile_bank_id: person?.payment_mobile_bank_id || ''
				}
			});
		}

		this.load();
	}

	load = async () => {
		const res: any = await UserService.admin.getBanks();
		this.setState({
			banks: res.banks || [],
			document_types: res.document_types || [],
			phonecodes: res.phonecodes || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await UserService.admin.updateBankData({
			user_id: this.props.item?.id,
			...this.state.form
		});
		this.props.onClose();
		Globals.showSuccess("Se ha actualizado los datos bancarios");
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<form id="modal-view-bank" onSubmit={ this.submit }>
				<div className="row">
					<div className="col-md-3">
						<Select
							disabled={ !checkPermission(Constants.MODULES.USERS,'write') }
							placeholder="Seleccionar"
							label="Código"
							name="payment_mobile_phonecode_id"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.payment_mobile_phonecode_id }
							options={ this.state.phonecodes.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-9">
						<Input
							disabled={ !checkPermission(Constants.MODULES.USERS,'write') }
							type="number"
							value={ this.state.form.payment_mobile_phone }
							name="payment_mobile_phone"
							label="Teléfono"
							maxLength={ 7 }
							onChange={ this.change } />
					</div>
					<div className="col-md-3">
						<Select
							disabled={ !checkPermission(Constants.MODULES.USERS,'write') }
							placeholder="Seleccionar"
							label="Tipo"
							name="payment_mobile_document_type_id"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.payment_mobile_document_type_id }
							options={ this.state.document_types.filter((i: any) => i.id == Constants.DOCUMENT_TYPES.V || i.id == Constants.DOCUMENT_TYPES.E).map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-9">
						<Input
							disabled={ !checkPermission(Constants.MODULES.USERS,'write') }
							type="number"
							value={ this.state.form.payment_mobile_document }
							name="payment_mobile_document"
							label="Cédula"
							maxLength={ 8 }
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Select
							disabled={ !checkPermission(Constants.MODULES.USERS,'write') }
							placeholder="Seleccionar"
							label="Banco"
							name="payment_mobile_bank_id"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.payment_mobile_bank_id }
							options={ this.state.banks.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					checkPermission(Constants.MODULES.USERS,'write') && (
						<div className="text-center">
							<Button type="submit" className="btn-save">
								Guardar
							</Button>
						</div>
					)
				}
			</form>
		)
	}
}

export default ModalBank;