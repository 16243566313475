import React from 'react';
import { Input, Button, CheckboxTable } from 'components';
import { ModeratorService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals } from 'utils';

interface Props {
	title?: string;
	onClose?: any;
	item?: any;
	modules?: any[];
}

class CreateModerator extends React.Component<Props> {

	state = {
		modules: [],
		form: {
			name: '',
	        email: '',
	        lastname: '',
	        password: '',
	        password_confirmation: ''
		}
	}

	componentDidMount() {
		this.setState({
			modules: Globals.clone(this.props.modules),
		},() => {
			if (this.props.item) {
				this.setState({
					form: {
						...this.state.form,
						name: this.props.item?.name || '',
						email: this.props.item?.email || '',
						lastname: this.props.item?.lastname || ''
					},
					modules: Globals.clone(this.props.modules).map((i: any) => {
						if (this.props.item?.module_permissions) {
							const permission = this.props.item?.module_permissions.find((item: any) => item.module_id == i.id);
							if (permission) {
								i.read = permission.read == true;
								i.write = permission.write == true;
								i.delete = permission.delete == true;
							}
						}
						return i;
					})
				});
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.state.form.password && this.state.form.password != this.state.form.password_confirmation) {
			Globals.showError("Las contraseñas no coinciden");
			return;
		}

		if (this.props.item != null) {
			await ModeratorService.admin.update({
				user_id: this.props.item?.id,
				...this.state.form,
				module_permissions: this.state.modules.map((i: any) => {
					return {
						module_id: i.id,
						read: i.read,
						write: i.write,
						delete: i.delete
					}
				})
			});
			Globals.showSuccess("Se ha modificado el moderador correctamente");
			this.props.onClose();
		}
		else {
			await ModeratorService.admin.create({
				...this.state.form,
				module_permissions: this.state.modules.map((i: any) => {
					return {
						module_id: i.id,
						read: i.read,
						write: i.write,
						delete: i.delete
					}
				})
			});
			Globals.showSuccess("Se ha creado el moderador correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	checkModule = (index: number, target: string) => {
		let modules: any = [...this.state.modules];
		if (target == 'active') {
			if (modules[index]['write'] && modules[index]['read'] && modules[index]['delete']) {
				modules[index]['read'] = false;
				modules[index]['write'] = false;
				modules[index]['delete'] = false;
			}
			else {
				modules[index]['read'] = true;
				modules[index]['write'] = true;
				modules[index]['delete'] = true;
			}
		}
		else {
			modules[index][target] = !modules[index][target];
		}
		this.setState({
			modules
		});
	}
	
	render() {
		return (
			<div id="modal-create-moderators">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-12">
						<div className="container-inputs row">
							<div className="col-md-6">
								<Input
									color="white"
									value={ this.state.form.name }
									name="name" 
									label="Nombre"
									onChange={ this.change } />
							</div>
							<div className="col-md-6">
								<Input
									color="white"
									value={ this.state.form.lastname }
									name="lastname" 
									label="Apellido"
									onChange={ this.change } />
							</div>
							<div className="col-md-6">
								<Input
									color="white"
									value={ this.state.form.email }
									name="email" 
									label="Correo"
									onChange={ this.change } />
							</div>
							<div className="col-md-6">

							</div>
							<div className="col-md-6">
								<Input
									type="password"
									color="white"
									value={ this.state.form.password }
									name="password" 
									label={ this.props.item != null ? "Cambiar Contraseña (Opcional)" : "Contraseña" }
									onChange={ this.change } />
							</div>
							<div className="col-md-6">
								<Input
									type="password"
									color="white"
									value={ this.state.form.password_confirmation }
									name="password_confirmation" 
									label="Repetir contraseña"
									onChange={ this.change } />
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="container-cyan">
							<h3>Módulos Autorizados</h3>
							<table>
								<thead>
									<tr>
										<td style={{ paddingLeft: '10px' }}></td>
										<td className="text-center">Ver</td>
										<td className="text-center">Escribir</td>
										<td className="text-center" style={{ paddingRight: '10px' }}>Eliminar</td>
									</tr>
								</thead>
								<tbody>
									{
										(this.state.modules || []).map((item: any, index: number) => {
											return (
												<tr>
													<td style={{ paddingLeft: '10px' }}>
														<div className="td-check">
															<CheckboxTable
																size="small" 
																active={ item.read && item.write && item.delete } 
																onClick={ () => this.checkModule(index,'active') } />
															{ item.name }
														</div>
													</td>
													<td className="td-center">
														<CheckboxTable 
															size="small" 
															active={ item.read } 
															onClick={ () => this.checkModule(index,'read') } />
													</td>
													<td className="td-center">
														<CheckboxTable 
															size="small" 
															active={ item.write } 
															onClick={ () => this.checkModule(index,'write') } />
													</td>
													<td className="td-center" style={{ paddingRight: '10px' }}>
														<CheckboxTable 
															size="small" 
															active={ item.delete } 
															onClick={ () => this.checkModule(index,'delete') } />
													</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
					<div className="col-md-12 text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default CreateModerator;