import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, TrashIcon, EditIcon, AddIcon } from 'assets/icons';
import ModalCreate from './modal-create';
import { ModeratorService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class AdminModerators extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nombre',
			'Correo',
			'Modulos autorizados',
			'Acciones'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null,
		modules: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Moderadores',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.loadModules();
		this.load();
	}

	loadModules = async () => {
		const res: any = await ModeratorService.admin.getModules({
			withoutLoading: true
		});
		this.setState({
			modules: res.modules
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ModeratorService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.moderators.rows,
			last_page: res.moderators.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el moderador?',async () => {
			await ModeratorService.admin.delete({
				user_id: item.id
			});
			this.load(true);
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	add = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-moderators">
		      	<Modal
		          className="modal-create-moderators"
		          visible={ visible }
		          title={ `${ this.state.item ? 'Editar' : 'Crear' } moderator` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		            modules={ this.state.modules }
		            item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-5">
								<Input
									value={ this.state.form.search }
									name="search"
									placeholder="Buscar"
									label="Nombre o correo"
									className="input-table"
									onSubmit={ () => this.load() }
									onChange={ (e: any) => {
										clearTimeout(this.timer);
										this.timer = setTimeout(() => {
											this.load(true);
										},1000);
										this.change(e); 
									} } />
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Moderadores" data={ this.state.data.length } header={ this.state.header } titleRight={
					<>
						{
							checkPermission(Constants.MODULES.MODERATORS,'write') && (
								<div className="container-add-table">
									<p>Nuevo</p>
									<button className="btn add-btn-table" onClick={ () => this.add() }>
										<img src={ AddIcon } />
									</button>
								</div>
							)
						}
					</>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.name } { i.lastname }</td>
								<td>{ i.email }</td>
								<td className="bold">{ i.module_permissions.filter((i: any) => {
									return i.read || i.write || i.delete;
								}).length } Autorizados</td>
								<td>
									{
										checkPermission(Constants.MODULES.MODERATORS,'write') && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.MODERATORS,'delete') && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ TrashIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminModerators);