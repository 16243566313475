import React from 'react';
import { Redirect } from 'react-router-dom';
import { Constants, checkPermission } from 'utils';

const Home = () => {
	if (checkPermission(Constants.MODULES.USERS,'read')) {
		return <Redirect to="/admin/users" />
	}
	else {
		return <Redirect to="/profile" />
	}
}

export default Home;