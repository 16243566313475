import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, checkPermission } from 'utils';
import { Button, Input } from 'components';
import { CommissionService } from 'services';
import { PercentageIcon } from 'assets/icons';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Commissions extends React.Component<Props> {

	state = {
		items: [],
		form: {
			amount: '',
			days: ''
		}
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Intereses y Comisiones',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await CommissionService.admin.get();
		this.setState({
			items: res.commissions || [],
			form: {
				amount: res.interests?.amount || '',
				days: res.interests?.days || ''
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await CommissionService.admin.save({
			items: this.state.items,
			...this.state.form,
		});
		Globals.showSuccess("Se han guardado los cambios correctamente");
	}

	changeCommission = (e: any, index: number) => {
		let items: any = [...this.state.items];
		items[index][e.target.name] = e.target.value;
		this.setState({
			items
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	getName = (index: number) => {
		switch(index) {
			case 1:
				return "Primer";
			break;

			case 2:
				return "Segundo";
			break;

			case 3:
				return "Tercer";
			break;
		}
	}
	
	render() {
		return (
			<div id="admin-commissions">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-6">
						<div className="container-white">
							<h3>Comisiones por posición (San Premium)</h3>
							<div className="container-positions">
								{
									this.state.items.map((item: any, index: number) => {
										if (item.position != null) {
											return (
												<div className="row container-input">
													<div className="col-md-8">
														<p>{ this.getName(item.position) } lugar</p>
													</div>
													<div className="col-md-4">
														<Input
															disabled={ !checkPermission(Constants.MODULES.COMMISSIONS,'write') }
															searchLeft
															onSubmit={ () => {} }
															searchIcon={ PercentageIcon }
															type="percentage"
															value={ item.percentage }
															name="percentage"
															placeholder="0.0"
															onChange={ (e: any) => this.changeCommission(e,index) } />
													</div>
												</div>
											)
										}
									})
								}		
							</div>			
							<h3>Comisión por San (San Básico)</h3>
							<div className="container-positions">
								{
									this.state.items.map((item: any, index: number) => {
										if (item.position == null) {
											return (
												<div className="row container-input">
													<div className="col-md-8">
														<p>Porcentaje de ganancia</p>
													</div>
													<div className="col-md-4">
														<Input
															disabled={ !checkPermission(Constants.MODULES.COMMISSIONS,'write') }
															searchLeft
															onSubmit={ () => {} }
															searchIcon={ PercentageIcon }
															type="percentage"
															value={ item.percentage }
															name="percentage"
															placeholder="0.0"
															onChange={ (e: any) => this.changeCommission(e,index) } />
													</div>
												</div>
											)
										}
									})
								}
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="container-white">
							<h3>Intereses</h3>
							<div className="container-positions">
								<div className="row container-input">
									<div className="col-md-8">
										<p>Intereses de mora</p>
									</div>
									<div className="col-md-4">
										<Input
											disabled={ !checkPermission(Constants.MODULES.COMMISSIONS,'write') }
											type="decimal"
											value={ this.state.form.amount }
											name="amount"
											placeholder="$ 0.0"
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
								<div className="row container-input">
									<div className="col-md-12">
										<div className="container-input-days">
											<p>Se aplica a partir del DÍA</p>
											<Input
												disabled={ !checkPermission(Constants.MODULES.COMMISSIONS,'write') }
												type="integer"
												value={ this.state.form.days }
												name="days"
												placeholder="0"
												onChange={ (e: any) => this.change(e) } />
											<p>DE RETRASO</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{
							checkPermission(Constants.MODULES.COMMISSIONS,'write') && (
								<div className="text-center" style={{ marginTop: '20px' }}>
									<Button type="submit">
										Guardar
									</Button>
								</div>
							)
						}
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Commissions);