import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, checkPermission } from 'utils';
import { UserService } from 'services';
import Viewer from 'react-viewer';

interface Props {
	onClose?: any;
	item?: any;
	reload?: any;
}

class ModalValidation extends React.Component<Props> {

	state = {
		visible: false,
		file: ''
	}

	submit = async (status: number, name: string) => {
		Globals.confirm(`¿Desea ${ name } este usuario?`,async () => {
			await UserService.admin.changeStatus({
				status,
				user_id: this.props.item?.id
			});
			Globals.showSuccess("Se ha cambiado el estatus del usuario");
			this.props.onClose();
		});
	}

	setVisible = (value: boolean) => {
		this.setState({
			visible: value
		});
	}

	view = (e: any, url: string) => {
		e.preventDefault();
		this.setState({
			visible: true,
			file: url
		});
	}

	requestDocuments = () => {
		Globals.confirm('¿Desea solicitar sus documentos nuevamente al usuario?',async () => {
			await UserService.admin.requestDocuments({
				user_id: this.props.item?.id
			});
			Globals.showSuccess("Se ha solicitado los documentos al usuario");
			this.props.reload();
		});		
	}
	
	render() {
		const { visible } = this.state;

		return (
			<>
				<Viewer
			      visible={ visible }
			      onClose={ () => this.setVisible(false) }
			      images={ [{ src: this.state.file }] }
			    />

				<div id="modal-users-valid">
					{
						this.props.item?.person?.document_photo && (
							<a href="#" onClick={ (e: any) => this.view(e,Constants.BASE_STORAGE + this.props.item?.person?.document_photo) }>
								<div className="item-document">
									<img src={ Constants.BASE_STORAGE + this.props.item?.person?.document_photo } className="document_photo" />
									<p>Documento de Identidad</p>
								</div>
							</a>
						)
					}
					{
						this.props.item?.person?.selfie && (
							<a href="#" onClick={ (e: any) => this.view(e,Constants.BASE_STORAGE + this.props.item?.person?.selfie) }>
								<div className="item-document">
									<img src={ Constants.BASE_STORAGE + this.props.item?.person?.selfie } className="document_photo" />
									<p>Selfie</p>
								</div>
							</a>
						)
					}
					{
						this.props.item?.person?.tax_document && (
							<a href="#" onClick={ (e: any) => this.view(e,Constants.BASE_STORAGE + this.props.item?.person?.tax_document) }>
								<div className="item-document">
									<img src={ Constants.BASE_STORAGE + this.props.item?.person?.tax_document } className="document_photo" />
									<p>RIF/Documento Fiscal</p>
								</div>
							</a>
						)
					}
					{
						checkPermission(Constants.MODULES.USERS,'write') && (this.props.item?.verified == Constants.VERIFIED_STATUS.VERIFIED || this.props.item?.verified == Constants.VERIFIED_STATUS.PENDING) && (
							<button className="btn btn-valid" onClick={ (e: any) => this.requestDocuments() }>
								Solicitar Documentos
							</button>
						)
					}
					{
						checkPermission(Constants.MODULES.USERS,'write') && this.props.item?.verified == Constants.VERIFIED_STATUS.REJECTED && (
							<div className="row">
								<div className="col-md-12 text-center">
									<Button type="button" className="btn-valid" onClick={ () => this.submit(Constants.VERIFIED_STATUS.VERIFIED,'Validar') }>
										Verificar al usuario
									</Button>
								</div>
							</div>
						)
					}
				</div>
			</>
		)
	}
}

export default ModalValidation;