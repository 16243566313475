import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, DownloadExcelIcon, ViewIcon, TrashIcon } from 'assets/icons';
import moment from 'moment';
import { CollectionService, PositionService } from 'services';
import ModalValidation from 'screens/admin/users/modal-validation';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	document: '',
	dues: '',
	san: '',
	location: '',
	accredited: ''
}

declare var window: any;

class AdminCollections extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nombre',
			'Documento',
			'Teléfono',
			'Ubicación',
			'San Participando',
			'Cuotas por pagar',
			'Cuotas canceladas',
			'Cuotas vencidas',
			'Mora',
			'Por cancelar',
			'Finalización',
			'Acreditado',
			'Acciones'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		item: null,
		visible_validation: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Cobranzas',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await CollectionService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form
		});
		this.setState({
			data: res.positions.rows,
			last_page: res.positions.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	download = async () => {
		const res: any = await CollectionService.admin.download({
			...this.state.form,
		});
		if (window.webkit && window.webkit.messageHandlers) {
			const message = 'open_url';
			const sendToNative = {
				message,
				url: Constants.BASE_STORAGE + res.url
			}
			const stringifiedMessageObj = JSON.stringify(sendToNative);
	        window.webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
	    }
	    else {
	    	Print(Constants.BASE_STORAGE + res.url);
	    }
	}

	openValidation = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_validation: true,
			item
		});
	}

	closeValidation = () => {
		this.setState({
			visible_validation: false
		},() => {
			this.load(true);
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		e.stopPropagation();
		Globals.confirm('¿Desea eliminar al usuario del SAN?',async () => {
			await PositionService.admin.delete({
				position_id: item.id
			});
			Globals.showSuccess("Se ha eliminado al usuario del SAN",() => {
				this.load();
			});
		});
	}
	
	render() {
		const { visible_validation } = this.state;

		return (
			<div id="admin-collections">
				<Modal
		          className="modal-users-valid"
		          visible={ visible_validation }
		          title="Documentos"
		          onClose={ () => this.closeValidation() }
		        >
		          <ModalValidation
		          	item={ this.state.item }
		          	reload={ async () => {
		          		let item: any = this.state.item;
		          		item.verified = Constants.VERIFIED_STATUS.REJECTED;
		          		await this.setState({
		          			item
		          		});
		          		this.load(true);
		          	} }
		            onClose={ () => this.closeValidation() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-11">
								<div className="col-md-2">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Nombre del usuario"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-2">
									<Input
										value={ this.state.form.document }
										name="document"
										placeholder="Buscar"
										label="Documento de Identidad"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-2">
									<Select
										placeholder="Seleccionar"
										label="Acreditación"
										name="accredited"
										onChange={ (e: any) => this.change(e,async () => {
											this.load(true);
										}) }
										value={ this.state.form.accredited }
										options={ [
											{ value: Constants.ACCREDITED.YES.toString(), label: 'Acreditados' },
											{ value: Constants.ACCREDITED.NO.toString(), label: 'Sin Acreditar' },
										] } />
								</div>
								<div className="col-md-2">
									<Input
										value={ this.state.form.dues }
										name="dues"
										placeholder="Buscar"
										label="Nº Cuotas vencidas"
										className="input-table"
										onSubmit={ () => this.load() }
										autocomplete="off"
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load();
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-2">
									<Input
										value={ this.state.form.location }
										name="location"
										placeholder="Buscar"
										label="Ubicación (Ciudad)"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-2">
									<Input
										value={ this.state.form.san }
										name="san"
										placeholder="Buscar"
										label="Nº de San"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Cobranzas registradas" data={ this.state.data.length } header={ this.state.header } titleRight={
					<>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>
									<p>{ i.user?.name } { i.user?.lastname }</p>
									<p className="bold">{ i.user?.email }</p>
									<p className="bold">Credolab: { i.user?.credolab_score } / { i.user?.credolab_id }</p>
								</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ ((i.user?.document_type?.name || '') + (i.user?.document_type_id == Constants.DOCUMENT_TYPES.PASSPORT ? ' - ' : '')) + i.user?.document }</td>
								<td className="phones">
									{
										i.user?.phone && (
											<>
												<p>{ (i.user?.phonecode?.name || '') + i.user?.phone }</p>
												<p>{ (i.user?.person?.alternative_phonecode?.name || '') + i.user?.person?.alternative_phone }</p>
											</>
										)
									}
								</td>
								<td>{ i.user?.person?.city?.name || '-' }</td>
								<td>{ i.san?.code }</td>
								<td className="bold">{ i.dues_pending }</td>
								<td className="blue">{ i.dues_paid }</td>
								<td>{ i.dues_expired }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>{ Globals.formatMiles(i.interests_to_pay) }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>{ Globals.formatMiles(i.pending_to_pay) }</td>
								<td>{ i.san?.finish_date ? moment(i.san?.finish_date).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.accreditations_count > 0 ? 'Si' : 'No' }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.openValidation(e,i?.user) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									{
										checkPermission(Constants.MODULES.COLLECTIONS,'delete') && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ TrashIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminCollections);