import { Api } from 'utils';

class CollectionService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/collections',params),
		download: async (params?: any) => Api.createResponse('admin/collections/download',params)
	}

}

export default CollectionService;