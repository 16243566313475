import { Api } from 'utils';

class ReminderService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/reminders',params),
		getTypes: async (params?: any) => Api.createResponse('admin/reminders/types',params),
		delete: async (params?: any) => Api.createResponse('admin/reminders/delete',params),
		create: async (params?: any) => Api.createResponse('admin/reminders/create',params),
		update: async (params?: any) => Api.createResponse('admin/reminders/update',params),
	}

}

export default ReminderService;