import { Api } from 'utils';

class ModeratorService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/moderators/get',params),
		create: async (params?: any) => Api.createResponse('admin/moderators/create',params),
		update: async (params?: any) => Api.createResponse('admin/moderators/update',params),
		getModules: async (params?: any) => Api.createResponse('admin/moderators/get-modules',params),
		delete: async (params?: any) => Api.createResponse('admin/moderators/delete',params)
	}

}

export default ModeratorService;